<div *ngIf="applicationService.overlayActive" class="overlay">
</div>
<app-top-nav-menu [class.nav-space]="showNav()" [class.no-nav-space]="!showNav()"></app-top-nav-menu>
<div [hidden]="!showNav()">
    <app-nav></app-nav>
</div>
<main [class.nav-space]="showNav()" [class.no-nav-space]="!showNav()">
  <div [class.container-fluid]="showNav()">
    <!-- animationContainer has a position: relative which stops is from overflowing under the navbar on transition -->
    <div [@fullPageAnimations]="prepareRoute(outlet)" class="animationContainer">

      <router-outlet #outlet="outlet"></router-outlet>

    </div>
  </div>
</main>

<div mdbModal #lockedModal="mdbModal" class="modal fade left" id="lockedModalTop" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: 'static', keyboard: false}">
  <div class="modal-dialog cascading-modal modal-avatar modal-sm" role="document">
    <div class="modal-content">
      <!--Header-->
      <div class="modal-header">
        <img src="../../../assets/images/default-user-icon.jpg"
             class="rounded-circle img-responsive avatar" *ngIf="!_userIconUrl">
        <img src="{{_userIconUrl}}"
             class="rounded-circle img-responsive avatar" *ngIf="_userIconUrl">
      </div>
      <!--Body-->
      <div class="modal-body text-center mb-1">

        <h5 class="mt-1 mb-2">{{this._firstName}} {{this._lastName}}</h5>

        <!-- <div *ngIf="!this.timedOut"> -->
          <br/>
          <h5>{{this.idleState}}</h5>
        <!-- </div> -->

        <!-- <div *ngIf="this.timedOut">
          <form [formGroup]="timedOutForm" (ngSubmit)="reAuthUser()">
            <div class="md-form ml-0 mr-0">
              <input type="password"
                     [ngClass]="{'is-invalid': timedOutForm.get('password').errors && timedOutForm.get('password').touched}"
                     id="password"
                     class="form-control"
                     formControlName="password"
                     mdbInput>
              <label for="password" class="primary-text">Your password</label>
              <div class="invalid-feedback text-left">Please enter your password.</div>
            </div>

            <div class="text-center">
              <button *ngIf="this._unlocking === false"
                      mdbBtn
                      color="cyan"
                      class="mt-1 waves-effect waves-light"
                      [disabled]="!this.timedOutForm.valid">
                Unlock
                <mdb-icon fas icon="sign-in-alt" class="ml-1"></mdb-icon>
              </button>
              <button *ngIf="this._unlocking === true"
                      mdbBtn
                      color="cyan"
                      class="mt-1 waves-effect waves-light"
                      disabled>
                Unlocking...
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              </button>
            </div>
          </form>
        </div> -->
      </div>
    </div>
  </div>
</div>

<div mdbModal #loadingSpinnerModal="mdbModal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="loadingSpinnerModal"
     aria-hidden="true"
     [config]="{backdrop: 'static', keyboard: false}">
  <div class="spinner-container" role="document">
    <div class="spinner-border spinner-size text-warning" role="status">
      <span class="visually-hidden"></span>
    </div>
    <p class="spinner-text">Loading, please wait...</p>
  </div>
</div>
