import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  Username = new Subject();
  FirstName = new Subject();
  LastName = new Subject();
  Email = new Subject();
  UserRoles = new Subject();  
  Expires = new Subject();
  Permissions = new Subject();
  UserIconUrl = new Subject();
  DefaultClientId = new Subject();
  CurrentClientId = new Subject();
  UserId = new Subject();
  Title = new Subject();
  columnWidths = new Subject();

  private _dataTableColumnWidths = 'dataTableColumnWidths';

  set setUserId(value) {
    this.UserId.next(value);
    localStorage.setItem('UserId', value);
  }

  get getUserId() {
    return localStorage.getItem('UserId')
  }

  set setCurrentClientId(value) {
    this.CurrentClientId.next(value);
    localStorage.setItem('CurrentClientId', value);
  }

  get getCurrentClientId() {
    return localStorage.getItem('CurrentClientId');
  }

  set setDefaultClientId(value) {
    this.DefaultClientId.next(value);
    localStorage.setItem('DefaultClientId', value);
  }

  get getDefaultClientId() {
    return localStorage.getItem('DefaultClientId')
  }

  set setUsername(value) {
    this.Username.next(value);
    localStorage.setItem('Username', value);
  }

  get getUsername() {
    return localStorage.getItem('Username')
  }

  set setFirstName(value) {
    this.FirstName.next(value);
    localStorage.setItem('FirstName', value);
  }

  get getFirstName() {
    return localStorage.getItem('FirstName');
  }

  set setLastName(value) {
    this.LastName.next(value);
    localStorage.setItem('LastName', value);
  }

  get getLastName() {
    return localStorage.getItem('LastName');
  }

  set setEmail(value) {
    this.Email.next(value);
    localStorage.setItem('Email', value);
  }

  get getEmail() {
    return localStorage.getItem('Email');
  }

  set setUserRoles(value) {
    this.UserRoles.next(value);
    localStorage.setItem('UserRoles', value);
  }

  get getUserRoles() {
    return localStorage.getItem('UserRoles');
  }
  

  set setExpires(value) {
    this.Expires.next(value);
    localStorage.setItem('Expires', value);
  }

  get getExpires() {
    return localStorage.getItem('Expires');
  }

  set setPermissions(value) {
    this.Permissions.next(value);
    localStorage.setItem('Permissions', value);
  }

  get getPermissions() {
    return localStorage.getItem('Permissions');
  }

  set setUserIconUrl(value) {
    this.UserIconUrl.next(value);
    localStorage.setItem('UserIconUrl', value);
  }

  get getUserIconUrl() {
    return localStorage.getItem('UserIconUrl');
  }

  set setTitle(value) {
    this.Title.next(value);
    localStorage.setItem('Title', value);
  }

  get getTitle() {
    return localStorage.getItem('Title');
  }


  set setColumnWidths(value) {
    this.columnWidths.next(value);
    localStorage.setItem(this._dataTableColumnWidths, value);
  }

  get getDataTableColumnWidths() {
    return localStorage.getItem(this._dataTableColumnWidths);
  }

  set setSocialSearchTerms(value) {
    localStorage.setItem('SocialSearchTerm', value);
  }

  get getSocialSearchTerms() {
    return localStorage.getItem('SocialSearchTerm');
  }

  public clearUserDetailStorage() {
    localStorage.removeItem('Username')
    localStorage.removeItem('FirstName')
    localStorage.removeItem('LastName')
    localStorage.removeItem('Email')
    localStorage.removeItem('UserRoles')    
    localStorage.removeItem('Expires')
    localStorage.removeItem('Permissions')
    localStorage.removeItem('UserIconUrl')
    localStorage.removeItem('DefaultClientId')
    localStorage.removeItem('Title')
  }

  public clearColumnWidthsStorage(){
    localStorage.removeItem(this._dataTableColumnWidths);
  }

constructor() { }

}
