import { Component, OnInit } from '@angular/core';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { NotificationService } from 'src/app/_services/notification.service';
import { ExecutionResultDto } from 'src/app/_models/execution-result-model';
import { ExecutionResult } from 'src/app/_models/execution-result-enum';
import * as moment from 'moment';
import { ContactUploadService } from 'src/app/_services/messaging/lists-and-contacts/contacts/contact.upload.service';
import { DataTableColumn } from '../../../../../_models/messaging/datatable-column';
import { PagerDtoRequest } from 'src/app/_models/messaging/pager-dto-request';
import { HistoryViewDtoAdapter } from 'src/app/_models/messaging/lists-and-contacts/contacts/history-view-dto-adapter';
import { HistoryViewDto } from 'src/app/_models/messaging/lists-and-contacts/contacts/history-view-dto';
import { HistoryViewColumnDtoAdapter } from 'src/app/_models/messaging/lists-and-contacts/contacts/history-view-column-dto-adapter';
import * as fileSaver from 'file-saver';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import ConstTableRowLimitOptions from 'src/app/views/shared/constants/app-constants';

@Component({
  selector: 'app-contacts-upload-history',
  templateUrl: './contacts-upload-history.component.html',
  styleUrls: ['./contacts-upload-history.component.scss'],
})

export class ContactsUploadHistoryComponent {

  _allColumns: DataTableColumn[] = [];
  _contentTypes = [{ ext: 'xlsx', type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'},
                  { ext: 'xls', type: 'application/vnd.ms-excel'},
                  { ext: 'csv', type: 'text/csv'}];
  _columnMode = ColumnMode;
  _columnWidths = [];
  _columns: DataTableColumn[] = [];
  _dateCulture = 'en-GB';
  _dateOptions = { day: 'numeric', month: 'numeric', year: 'numeric' };
  _originalFileName: string;
  _previousUrl: string;
  _rows: HistoryViewDto[] = [];

  // options for table row limit
  public pageLimitOptions = ConstTableRowLimitOptions;

  // variables for the table
  _currentPage = 1;
  _pageCount = 0;
  _pageLimit = 10;
  _pageOffset = 0;
 

  constructor(
    private notificationService: NotificationService,
    private historyViewDtoAdapter: HistoryViewDtoAdapter,
    private historyViewColumnDtoAdapter: HistoryViewColumnDtoAdapter,
    private contactUploadService: ContactUploadService,    
    private location: Location) { }

  ngOnInit() {

    this.getHistory();
    
  }

  base64ToBlob(b64Data, sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }
    var fileExtension = this.getFileExtension(this._originalFileName);
    const type = this._contentTypes.find(x => x.ext === fileExtension).type;
    return new Blob(byteArrays, { type });
  }

  downloadErrorsFile(rowIndex: number) {
    const selectedRow = this._rows[rowIndex];

    this.contactUploadService.downloadErrorsFile(selectedRow.fileId).subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) {        
        this._originalFileName = this.getOriginalErrorsFilename(selectedRow.errorsFileName);
        const blob = this.base64ToBlob(executionResultDto.data);
        fileSaver.saveAs(blob, selectedRow.errorsFileName);
        this.notificationService.showSuccess(executionResultDto.message);
      } else {
        this.notificationService.showError(executionResultDto.message);
      }
    }
    );
  }

  downloadFile(rowIndex: number) {

    const selectedRow = this._rows[rowIndex];

    this.contactUploadService.downloadFile(selectedRow.fileId).subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) {
        this._originalFileName = selectedRow.fileName;
        const blob = this.base64ToBlob(executionResultDto.data);
        fileSaver.saveAs(blob, selectedRow.fileName);
        this.notificationService.showSuccess(executionResultDto.message);
      } else {
        this.notificationService.showError(executionResultDto.message);
      }
    }
    );
  }

  isDate(value) {
    return moment(value.toString().substring(0, 10), 'YYYY-MM-DD', true).isValid();
  }

  getFileExtension(fileName) {
    return fileName.split('.')[1];
  }

  getOriginalErrorsFilename(filename: string) {
    const filenameArray = filename.split('.');
    const originalFileName = filenameArray[0].split('-');
    return `${originalFileName[0]}-${originalFileName[1]}.${filenameArray[1]}`;
  }

  getHistory(pageInfo?: any): void {   

    const pageNumber = pageInfo ? pageInfo : 1;    

    const pager = new PagerDtoRequest({ page: pageNumber,
                                        pageSize: this._pageLimit,
                                        sortColumn: 'UploadedDate',
                                        sortDirection: 'DESC'
                                      });

    this.contactUploadService.getHistory(pager).subscribe((executionResultDto: ExecutionResultDto) => {

      if (executionResultDto.executionResult === ExecutionResult.success) {
        this._pageCount = executionResultDto.data.Total;
        this._pageOffset = pageNumber - 1;

        this._allColumns = this.historyViewColumnDtoAdapter.adapt(executionResultDto.data);
        this._columns = this._allColumns.filter(x => x.prop !== 'fileId');

        this._rows = this.historyViewDtoAdapter.adapt(executionResultDto.data);

      } else {
          this.notificationService.showError(executionResultDto.message);
      }
    }
    );
  }

  goBack() {
    this.location.back();
  }

   // allow the user to change the max number of rows showed in the table
   onLimitChange(limit: any): void {
    this._pageLimit = limit.replace(/\D/g,'');
    this.getHistory();
  }  
}
