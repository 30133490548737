import { Component, OnInit, HostListener, ViewChild, Injector } from '@angular/core';
import { MessageSaveModel } from 'src/app/_models/messaging/messages/email-messages/message-save-model';
import { MessageSaveModelAdapter } from 'src/app/_models/messaging/messages/email-messages/message-save-model.adapter';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { FolderDto } from 'src/app/_models/messaging/messages/folder-dto';
import { FolderDtoAdapter } from 'src/app/_models/messaging/messages/folder-dto.adapter';
import { EmailMessagesService } from 'src/app/_services/messaging/messages/email-messages/email-messages.service';
import { NotificationService } from 'src/app/_services/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageFolderService } from 'src/app/_services/messaging/messages/message-folder.service';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from 'src/app/_services/system/localStorage/localStorage.service';
import { MessageTypeEnum } from 'src/app/_models/messaging/messages/message-type-enum';
import { ComponentCanDeactivate } from 'src/app/_guards/pending-changes-guard';
import { Observable } from 'rxjs';
import { MatDialog, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { LastActiveService } from 'src/app/_services/system/last-activity-service';
import { UnsavedChangesModalComponent } from 'src/app/views/shared/modals/unsaved-changes/unsaved-changes-modal.component';
import { InactivityWarningModalComponent } from 'src/app/views/shared/modals/unsaved-changes/inactivity-warning-modal.component';
import { CookieStorageService } from 'src/app/_services/system/cookies/cookieStorage.service';

@Component({
  selector: 'app-email-builder-mosaico',
  templateUrl: './email-builder-mosaico.component.html',
  styleUrls: ['./email-builder-mosaico.component.scss']
})
export class EmailBuilderMosaicoComponent implements OnInit, ComponentCanDeactivate {

  @ViewChild('saveMessageModal', { static: true }) saveMessageModal: ModalDirective;

  // Iframe Window Var.
  _editorWindow;

  // Model Variables.
  _message: MessageSaveModel;
  _messageFolders: Array<FolderDto> = [];

  // Boolean Variables for states of elements & to prevent actions from happening.
  _newMessageState = true;
  _hasMessageSent = false;
  _folderSelectOpen: boolean = false;
  _saving: boolean = false;
  _stayOnPage: boolean = true;

  // Simple Variables.
  _messageFoldersDropdown: Array<any> = [];
  _messageID: number;
  _emailBuilderURL: string = environment.emailBuilderURL;
  _submitButtonText: string;
  _sendAfterSave: boolean;

  // Save Form
  _saveMessageForm = new UntypedFormGroup({
    messageName: new UntypedFormControl('', Validators.required),
    emailSubject: new UntypedFormControl('', Validators.required),
    messageFolder: new UntypedFormControl('', Validators.required),
    fromName: new UntypedFormControl('', Validators.required),
    fromAddress: new UntypedFormControl('', [Validators.required, Validators.email]),
    replyToAddress: new UntypedFormControl('', [Validators.required, Validators.email]),
  });

  lastActivityDate: Date;
  activityCheckInterval: any;
  matDialog: MatDialog;
  dialogRef: MatDialogRef<InactivityWarningModalComponent>;

  constructor(
    private messageSaveModelAdapter: MessageSaveModelAdapter,
    private messageFolderService: MessageFolderService,
    private messageFolderDtoAdapter: FolderDtoAdapter,
    private emailMessagesService: EmailMessagesService,
    private notificationService: NotificationService,
    private activatedRoute: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private cookieStorageService: CookieStorageService,
    private router: Router,
    private lastActiveService: LastActiveService,
    injector: Injector    
  ) {
    this.matDialog = injector.get(MatDialog);
   }   

  @HostListener('window:message', ['$event']) onPostMessage(event) {    
    if (event && event.data) {
      this._submitButtonText = event.data[1];
      this._sendAfterSave = event.data[1] === 'send' ? true : false;
      this.messageToSave(event.data[0]);
    }
  }

  @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
      return this._stayOnPage ? false: true;     
  }

  ngOnInit() {

    this.activatedRoute.params.subscribe( params =>
      this._messageID = params['messageID']
    );

    if (this._messageID) {
        this._newMessageState = false;
        this.emailMessagesService.getMessage(this._messageID).subscribe( result => {
          this._message = this.messageSaveModelAdapter.adapt(result.data);
          this.initEmailBuilder();
        }, error => {
          console.log(error);
        })
    } else {
      this.initEmailBuilder();
    }
    this.getMessageFolders();
    this.lastActiveService.lastActive$.subscribe(lastActivityDate=>
    {
      this.lastActivityDate = lastActivityDate;
    });
        
        
    this.activityCheckInterval = setInterval(() => {
      var now = new Date();
      if(this.lastActivityDate < new Date(now.getTime() - (15 * 60 * 1000))){
        if(this.dialogRef == null || this.dialogRef.getState() !== MatDialogState.OPEN){
          this.dialogRef = this.matDialog.open(InactivityWarningModalComponent, { id:'inactivity-warning-modal' });
        }       
      }                 
    }, 60000);
  }

  ngOnDestroy() {    
    clearInterval(this.activityCheckInterval);                         
   }

  folderSelectOpened() {
    this._folderSelectOpen = true;
  }

  folderSelectClosed() {
    this._folderSelectOpen = false;
  }

  getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
      if (typeof value === "object" && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
  }

  initEmailBuilder() {
    if (this._newMessageState) {
      this._message = this.messageSaveModelAdapter.createEmpty();
    } else {

    }

    var messageData = {
      MessageData :this._message,
      Token: this.cookieStorageService.getToken,
      Username: this.localStorageService.getUsername,
      ClientID: this.localStorageService.getCurrentClientId
    }

    this._editorWindow = document.getElementById('email-builder-frame');
    setTimeout(() => {
      this._editorWindow.contentWindow.postMessage(JSON.stringify(messageData, this.getCircularReplacer()), '*');
    }, 2000);
  }

  messageToSave(messageData: MessageSaveModel) {
    this._message.HtmlContent = messageData.HtmlContent;
    this._message.MetaContent = messageData.MetaContent;
    this._message.EmailContent = messageData.EmailContent;

    this.populateMessageModal();
    this.saveMessageModal.show();
  }

  getMessageFolders() {
    this.messageFolderService.getAll().subscribe(result => {
      this._messageFolders = this.messageFolderDtoAdapter.adaptArray(result.data);
    });
  }

  populateMessageFolderDropdown() {
    this._messageFoldersDropdown = [];
    this._messageFolders.forEach((folder: FolderDto) => {
      this._messageFoldersDropdown = [... this._messageFoldersDropdown, {
        value: folder.id,
        label: folder.name
      }];
    });
  }

  populateMessageModal() {
    this.populateMessageFolderDropdown();
    this._saveMessageForm.patchValue({
      messageName: this._message.MessageName,
      emailSubject: this._message.EmailSubject,
      messageFolder: this._message.FolderID,
      fromName: this._message.FromName,
      fromAddress: this._message.FromAddress,
      replyToAddress: this._message.ReplyToAddress
    });
  }

  // if the user clicks save and exit, return them to the message screen
  // otherwise, just close the modal
  saveMessageSubmit(stayOnPage: boolean) {
    this._stayOnPage = stayOnPage;
    this._saving = true;
    this._message.MessageName = this._saveMessageForm.value.messageName;
    this._message.EmailSubject = this._saveMessageForm.value.emailSubject;
    this._message.FolderID = this._saveMessageForm.value.messageFolder;
    this._message.FromName = this._saveMessageForm.value.fromName;
    this._message.FromAddress = this._saveMessageForm.value.fromAddress;
    this._message.ReplyToAddress = this._saveMessageForm.value.replyToAddress;

    this.emailMessagesService.saveMessage(this._message).subscribe(result => {
      this.notificationService.showSuccess(result.message);
      this.saveMessageModal.hide();
      this._saving = false;
      this._message.MessageID = result.data.MessageID;
      if (this._sendAfterSave) {
        this.router.navigate(['messages/message-send', this._message.MessageID, MessageTypeEnum.email]);
      }
      else {        
        if(!stayOnPage) this.router.navigate(['messages/', this._message.FolderID]);
      }

    }, error => {
      console.log(error);
      this._saving = false;
    });
  }

}
