import { CookieService } from "ngx-cookie-service";
import { Subject } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})

export class CookieStorageService {
  Token = new Subject();
  Expires = new Subject();

  constructor(private cookieService: CookieService) { }

  set setToken(value) {
    this.cookieService.set("VBToken", value);
    this.Token.next(value);
  }

  get getToken() {
    return this.cookieService.get("VBToken");
  }


  public clearDetailStorage() {
    this.cookieService.delete("VBToken");
    this.cookieService.delete("idToken");
    this.cookieService.delete("sessionId");
  }


}