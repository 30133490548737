import { Component, ViewChild, OnInit, Injector } from '@angular/core';
import { AuthService } from 'src/app/_services/admin/auth/auth.service';
import { fullPageSlide } from './animations/route-animations';
import { NavigationStart, Router, RouterOutlet } from '@angular/router';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { environment } from 'src/environments/environment';
import { Keepalive } from '@ng-idle/keepalive';
import { ModalDirective, MDBSpinningPreloader } from 'ng-uikit-pro-standard';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { LoginRequestDto, LoginRequestAdapter } from './_models/admin/login-request-model';
import { LocalStorageService } from './_services/system/localStorage/localStorage.service';
import { ApplicationService } from './_services/system/application.service';
import { LoadingSpinnerService } from './_services/loading-spinner/loading-spinner.service';
import { MatDialog } from '@angular/material/dialog'; 
import { Subscription } from 'rxjs';

 export let browserRefresh = false;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    fullPageSlide,
  ]
})

export class AppComponent implements OnInit {
  title = 'VoiceBox';

  //ng-idle vars
  idleState: string = 'Not Started'; 
  lastPing?: Date = null;

  timedOutForm: UntypedFormGroup
  _loginDto: LoginRequestDto

  _username: string = '';
  _firstName: string = '';
  _lastName: string = '';
  _userIconUrl: string = '';
  _unlocking: boolean = false;
  matDialog: MatDialog;

  subscription: Subscription;

  @ViewChild('lockedModal', { static: true }) lockedModal: ModalDirective;
  @ViewChild('loadingSpinnerModal', { static: true }) loadingSpinner: ModalDirective;
  
  constructor(
    private authService: AuthService,
    private idle: Idle,
    private keepalive: Keepalive,
    private formBuilder: UntypedFormBuilder,
    private loginRequestAdapter: LoginRequestAdapter,
    private localStorageService: LocalStorageService,
    private mdbSpinningPreloader: MDBSpinningPreloader,
    private loadingSpinnerService: LoadingSpinnerService,
    public applicationService: ApplicationService,    
    injector: Injector,
    private router: Router
  ) {
    // // set the idle timeout length
    // idle.setIdle(environment.sessionIdlePeriod);
    // // set timeout lenght after idle
    // idle.setTimeout(environment.sessionTimeoutPeriod);

    // // this listens for default interactions like scrolling, clicking and moving mouse around the screen
    // idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle';
      console.log(this.idleState);
      this.lockedModal.hide();
      //this.reset();
    });

    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';      
      try{
        this.matDialog = injector.get(MatDialog);
        this.matDialog['_dialog'].getDialogById("inactivity-warning-modal").close();
      }
      catch{}    
      localStorageService.clearUserDetailStorage();      
      console.log(this.idleState);
    });

    idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!'
      console.log(this.idleState);
      this._firstName = localStorageService.getFirstName;
      this._lastName = localStorageService.getLastName;
      this._username = localStorageService.getUsername;
      this._userIconUrl = localStorageService.getUserIconUrl;
      try{
        this.matDialog = injector.get(MatDialog);
        this.matDialog['_dialog'].getDialogById("inactivity-warning-modal").close();
      }
      catch{}
      this.lockedModal.show();
    });    

    // // sets the ping interval to 5 minutes
    // keepalive.interval(300);

    // keepalive.onPing.subscribe(() => {      
    //   // this.lastPing = new Date();
    //   // if (this.timedOut){
    //   //   try{
    //   //     this.matDialog = injector.get(MatDialog);
    //   //     this.matDialog['_dialog'].getDialogById("inactivity-warning-modal").close();
    //   //   }
    //   //   catch{}
    //   // }
    //   // authService.reGenToken().subscribe((result) => {
    //   //   this.authService.awsGetCurrentSession();
    //   //   localStorageService.setToken = result.data;
    //   // });
    // });

    this.subscription = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        browserRefresh = !router.navigated;
      }
  });

    // this.reset();
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.mdbSpinningPreloader.stop();
    }, 1000);

    this.loadingSpinnerService.loader().subscribe((isDataLoaded) => {
      isDataLoaded ? this.loadingSpinner.hide() : this.loadingSpinner.show();
    });
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
  }

  // reset() {
  //   if (this.loggedIn()) {
  //     this.idle.watch();
  //     this.idleState = 'Idle Started.';      
  //   }
  // }

  // stopIdle() {
  //   this.idle.stop();
  // }

  showNav() {
    const tokenActive = this.authService.loggedIn();
    return tokenActive;
  }

  loggedIn() {
    return this.authService.loggedIn();
  }

  // generateForm() {
  //   this.timedOutForm = this.formBuilder.group({
  //     username: [],
  //     password: ['', Validators.required]
  //   });
  // }

  // reAuthUser() {
  //   this._unlocking = true;
  //   if (this.timedOutForm.valid) {
  //     this._loginDto = this.loginRequestAdapter.adapt({
  //       Username: this._username,
  //       Password: this.timedOutForm.get('password').value
  //     });

  //     // refresh the cognito session
  //     // as the model is case sensitive, we need to create a new model that works with the Cognito function
  //     var cognitoSignIn: any = {
  //       username: this._username,
  //       password: this.timedOutForm.get('password').value
  //     };

  //     this.authService.awsSignIn(cognitoSignIn);
  //     this.authService.awsGetCurrentSession();
  //     this.authService.login(this._loginDto, true).subscribe(data => {
  //       this._unlocking = false;
  //       this.lockedModal.hide();
  //       this.timedOutForm.reset();
  //       this.reset();
  //     }, () => {
  //       this._unlocking = false;
  //     });

  //   }
  // }
}
