import { APP_INITIALIZER, FactoryProvider } from '@angular/core';
import { PermissionsService } from '../_services/system/Permissions/permissions.service';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthService } from '../_services/admin/auth/auth.service';

export function loadPermissionsFactory(permissionsService: PermissionsService, authService: AuthService) {
  return () => from(authService.awsGetCurrentSession()).pipe(
    switchMap((ans) => {
      if (typeof ans !== 'string') {
        return permissionsService.getPermission()
         .pipe(
          catchError(err => 
            {
              if(err.status == 401){
                authService.logout();
              }              
              return of(null)
          }));
      } else {
        return of(null);
      }
    })
  );
}

export const loadConfigProvider: FactoryProvider = {
  provide: APP_INITIALIZER,
  useFactory: loadPermissionsFactory,
  deps: [PermissionsService, AuthService],
  multi: true
};
