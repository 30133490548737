<div class="nav-bar-div">
  <div  class="app-buttom-div" *ngFor="let application of userApps" 
   >
 
  <a
        class="app-btn"
        [ngClass]="{ selected : application.name === 'VoiceBox' }"
        (click)="openLink(application.name)"
      >
      <img
    src="../../../../assets/images/logos/{{ application.name }}.svg"
    class="app-image"
    alt="app-icons" id="{{application.name}}"
  />
      {{ application.name }}
  </a>
  
    </div>
  </div> 
  