import {Component, OnInit, ViewChild} from '@angular/core';
import { forkJoin } from 'rxjs';
import { environment } from "src/environments/environment";
import { CookieService } from "ngx-cookie-service";
import { UserApp } from 'src/app/_models/admin/users/user-app-dto';
import { AuthService } from 'src/app/_services/admin/auth/auth.service';


@Component({
  selector: 'app-top-nav-menu',
  templateUrl: './top-nav-menu.component.html',
  styleUrls: ['./top-nav-menu.component.scss']
})
export class TopNavMenuComponent implements OnInit { 
  userApps: UserApp[];

  ngOnInit(): void {    
   this.authService.getUserApps()
   .subscribe((userApps: UserApp[]) =>  {
      this.userApps = userApps.filter(a => a.isAvailable).sort((a, b) => a.name.localeCompare(b.name));      
    });
  }


  constructor(private authService: AuthService,   
    private cookieService: CookieService,
    ) {
    
  }
  

  // open the correct link in a new tab
  openLink(app: string) {
    switch (app) {        
      case 'BrainBox':
        window.open(environment.brainBoxUrl + '?sessionId=' + this.cookieService.get('sessionId')+ '&VBToken=' + this.cookieService.get('VBToken') + '&userId=' + this.cookieService.get('userId'), "_blank");
        break;
      case 'LeadBox':        
        window.open(environment.leadBoxUrl + '?sessionId=' + this.cookieService.get('sessionId')+ '&VBToken=' + this.cookieService.get('VBToken') + '&userId=' + this.cookieService.get('userId'), "_blank");
        break;
      case 'ToolBox':        
        window.open(environment.loginURL+ 'toolBox/new-users?sessionId=' + this.cookieService.get('sessionId'), "_blank");
        break;
      case 'StockBox':
          window.open(environment.stockBoxUrl + '?sessionId=' + this.cookieService.get('sessionId')+ '&VBToken=' + this.cookieService.get('VBToken') + '&userId=' + this.cookieService.get('userId'), "_blank");
          break;
      default:
        break;
    }
  }
}
