import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class QueryBuilderHelper {
    constructor() { }

    //Method to remove all occurrences of ISNULL from string
    removeIsNullFromSQL(sql) {
        var modifiedSQLQuery = '';

        if (sql.includes('ISNULL')) modifiedSQLQuery = sql.replaceAll('ISNULL(','').replaceAll(', \'\')', '');
        else modifiedSQLQuery = sql;

        return modifiedSQLQuery;
  }

  updateConsentEmailAndConsentSMSFields(filter) {
  
    let value = filter;

    if (filter.includes("Consent_Email") || filter.includes("Consent_SMS")) {

      switch (filter) {
        case 'ISNULL(Consent_Email, \'\') != \'1\'':
          value = 'Consent_Email = 0'
          break;
        case 'ISNULL(Consent_Email, \'\') = \'1\'':
          value = 'Consent_Email is null or  Consent_Email = 1'
          break;
        case 'ISNULL(Consent_Email, \'\') != \'0\'':
          value = 'Consent_Email is null or  Consent_Email = 1'
          break;
        case 'ISNULL(Consent_Email, \'\') = \'0\'':
          value = 'Consent_Email = 0'
          break;
        case 'ISNULL(Consent_SMS, \'\') != \'1\'':
          value = 'Consent_SMS = 0'
          break;
        case 'ISNULL(Consent_SMS, \'\') = \'1\'':
          value = 'Consent_SMS is null or  Consent_SMS = 1'
          break;
        case 'ISNULL(Consent_SMS, \'\') != \'0\'':
          value = 'Consent_SMS is null or  Consent_SMS = 1'
          break;
        case 'ISNULL(Consent_SMS, \'\') = \'0\'':
          value = 'Consent_SMS = 0'
          break;
        default:
          value = filter;
      }
    }

    return value;
  }
}
