import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { DashboardStatsModel } from 'src/app/_models/dashboard/dashboard-stats-model';
import { DashboardStatsModelAdapter } from 'src/app/_models/dashboard/dashboard-stats-model-adapter';
import { ExecutionResult } from 'src/app/_models/execution-result-enum';
import { ExecutionResultDto } from 'src/app/_models/execution-result-model';
import { LinkedInAccessCodeRequest } from 'src/app/_models/social-media/networks/LinkedIn/linked-in-access-code-request';
import { AuthService } from 'src/app/_services/admin/auth/auth.service';
import { DashboardService } from 'src/app/_services/dashboard/dashboard.service';
import { NotificationService } from 'src/app/_services/notification.service';
import { LinkedInService } from 'src/app/_services/social-media/networks/linkedIn.service';
import { CookieStorageService } from 'src/app/_services/system/cookies/cookieStorage.service';
import { PermissionsService } from 'src/app/_services/system/Permissions/permissions.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  codeParam: string;
  errorParam: string;
  errorDescriptionParam: string;
  loginResponse: string = '';
  userId: string;
  userToken: string;

  linkedInAccessCodeRequest: LinkedInAccessCodeRequest = { AccessCode: ''};
  _dashboardStatsModel: DashboardStatsModel;

  constructor(private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationService,
    private cookieStorageService: CookieStorageService,
    private permissionService: PermissionsService,
    private linkedInService: LinkedInService,
    private dashboardService: DashboardService,
    private dashboardStatsModelAdapter: DashboardStatsModelAdapter) { }

  // try to retrieve the params from the previous url to attempt to log in (for arriving from Marketing.Delivery platform)
  // if there are parameters, attempt to log in to VB
  // if not, check if already logged in and do nothing if so
  // if not logged in and can't log in, return to login screen and logout
  // also check for returns back from the social media logins and move them to the correct screen (network management)
  async ngOnInit() {
    this.userToken = this.route.snapshot.queryParamMap.get('userToken');
    this.userId = this.route.snapshot.queryParamMap.get('userId');
    var sessionId = this.route.snapshot.queryParamMap.get('sessionId');
    this.codeParam = this.route.snapshot.queryParamMap.get('code');
    this.errorParam = this.route.snapshot.queryParamMap.get('error');
    this.errorDescriptionParam = this.route.snapshot.queryParamMap.get('error_description');
    this._dashboardStatsModel = this.dashboardStatsModelAdapter.createEmpty();
    try {
      if (this.userId && this.userToken) {
        await this.authService.loginWithId(this.userId, this.userToken, sessionId, true);
        this.notificationService.showSuccess("Login successful!");
        history.pushState(null, "", location.href.split("?")[0]);
        window.location.reload();               
        return true;
      }
      else if (this.authService.loggedIn()) {
        this.permissionService.getPermission().subscribe(() => {
          this.getDashboardStats();
        });
        
        if(this.codeParam)
        {
          this.linkedInAccessCodeRequest.AccessCode = this.codeParam;
          this.linkedInService.signInWithLinkedIn(this.linkedInAccessCodeRequest).subscribe((executionResultDto: ExecutionResultDto) => {
            if (executionResultDto.executionResult === ExecutionResult.success) {    
              this.notificationService.showSuccess(executionResultDto.message);
            } else {
              this.notificationService.showError(executionResultDto.message);
            }

            this.router.navigate(['network-management']);
          });          
        }
        else if(this.errorParam)
          {
            this.notificationService.showError(decodeURI(this.errorDescriptionParam));
            this.router.navigate(['network-management']);
          }
        
        return true;
      }
    } catch (error) {
      console.log(error);
    }

    this.logout();
    this.notificationService.showError('Login attempt unsuccessful.');
    window.location.assign(environment.loginURL);
  }

  async logout() {   
    localStorage.clear();
    this.cookieStorageService.clearDetailStorage();
   
    this.permissionService.setPermissionsModel = null;
    window.location.assign(environment.loginURL);
  }

  getDashboardStats(){
    this.dashboardService.get().subscribe((executionResultDto: ExecutionResultDto) => {          
      if (executionResultDto.executionResult === ExecutionResult.success) {
        this._dashboardStatsModel = this.dashboardStatsModelAdapter.adapt(executionResultDto.data);
      } else {
        this.notificationService.showError(executionResultDto.message);
      }
    });
  }

  getFaIconName(percentage) {
    if (percentage > 0) {          
      return 'arrow-up';      
    } else if (percentage) {
      return 'arrow-down';      
    } else {      
      return 'minus-square';      
    }
  }

  getPercentageColour(percentage) {
    if (percentage > 0) { 
      return 'green'
    } else if (percentage < 0) {      
      return 'red';
    } else {
      return 'grey';
    }
  }

}
