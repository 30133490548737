import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '../../system/localStorage/localStorage.service';
import { ExecutionResultAdapter } from 'src/app/_models/execution-result-model';
import { Auth } from 'aws-amplify';
import { CognitoUserAttributesDto } from 'src/app/_models/admin/users/cognito/cognito-user-attributes';
import { CookieStorageService } from '../../system/cookies/cookieStorage.service';
import { CookieService } from 'ngx-cookie-service';
import { UserApp } from 'src/app/_models/admin/users/user-app-dto';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  _baseUrl: string = environment.apiURL;
  jwtHelper = new JwtHelperService();
  cognitoUserSession: any;
  setupTwoFactorCode: string;
  localUserModel: any = {};
  signedInUser: any;
  userAuthenticated: boolean;
  signedUpUser: any = {};
  cognitoSignUp: boolean;
  newUserLocal: any = {};
  awsRegisterResult: string;
  cognitoUserAttributes: CognitoUserAttributesDto;
  localUserSignUp: string;
  awsNewUser: any = {};

  constructor(private http: HttpClient,
    private localStorageService: LocalStorageService,
    private cookieStorageService: CookieStorageService,
    private cookieService: CookieService,
    private executionResultAdapter: ExecutionResultAdapter) { }
  

  // send a login request using only the userId - this will be after they have redirected from Marketing.Delivery platform
  // the user doesn't need to login again as they will already have
  // an active session - we can use the token to pass over to Voicebox to validate the user
  async loginWithId(userId: string, userToken: string, sessionId: string, saveUserToStorage: boolean) {

    var endpointUrl = this._baseUrl + 'Account/LoginWithUserId' + '?userToken=' + userToken + '&userId=' + userId;
    console.log(endpointUrl);
    var user = await this.http.get(endpointUrl).pipe().toPromise();

    //ToDo remove this when we migrate to cookie auth
    var cognitoToken = this.cookieService.get("idToken");
    if (cognitoToken == null || cognitoToken == ''){
      var sesionTokenDto = await this.http.get(environment.toolboxApiUrl + 'SessionIdToken' + '?sessionId=' + sessionId).pipe().toPromise();
      this.saveSessionToCookies(sesionTokenDto, sessionId, userId, userToken);
    }

    if (saveUserToStorage === true) {
      this.saveUserToLocalStorage(user);
    }
  }

  saveSessionToCookies(sesionTokenDto: any, sessionId: string, userId: string, VBToken: string){
    this.cookieService.set('sessionId', sessionId);
    this.cookieService.set("idToken", sesionTokenDto.token);
    this.cookieService.set("userId", userId);
    this.cookieService.set("VBToken", VBToken);
  }

  saveUserToLocalStorage(user: any) {
    if (user) {      
      this.localUserModel = user;
      this.localStorageService.setUserId = user.UserId;
      this.localStorageService.setFirstName = user.FirstName;
      this.localStorageService.setLastName = user.LastName;
      this.localStorageService.setEmail = user.Email;
      this.localStorageService.setUserRoles = user.UserRoles;
      this.cookieStorageService.setToken = user.Token;
      this.localStorageService.setExpires = user.Expires;
      this.localStorageService.setPermissions = user.Permissions;
      this.localStorageService.setUsername = user.UserName;
      this.localStorageService.setUserIconUrl = user.UserIconUrl;
      this.localStorageService.setDefaultClientId = user.DefaultClient;
      this.localStorageService.Title = user.Title;
      if (localStorage.getItem("CurrentClientId") == null) {
        this.localStorageService.setCurrentClientId = user.DefaultClient;
      }
    }
  }

  
  
  // sign the user out of Cognito - this can be either just for the current app, or globally
  async logout() {
    this.cookieStorageService.clearDetailStorage();
  }


  loggedIn() {
    const token = this.cookieStorageService.getToken;
    return !this.jwtHelper.isTokenExpired(token);
  }

  register(model: any) {
    return this.http.post(this._baseUrl + 'Account/Register', model);
  }

  reGenToken() {
    return this.http
      .get(this._baseUrl + 'Account/UpdateUserSession')
      .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));;
  }

  async awsGetCurrentSession(): Promise<any> {
    try {
      return await Promise.resolve();
    } catch (error) {
      return error;
    }
  }

  getExtenedToken() {
    return this.http.get(this._baseUrl + 'Account/GetExtendedToken')
      .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  async passwordReset(model: any): Promise<any> {
    return this.http.post(this._baseUrl + 'Account/ResetPassword', model).toPromise();
  }

  getUserApps() {    
    return this.http.get(environment.toolboxApiUrl + "UserApps").pipe(
      map((response: UserApp[]) => {        
        return response;
      })
    );
  }
}
